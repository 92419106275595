<template>
  <div class='page-box'>
    <base-screen class="custom-screen">
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <!-- <a-row> -->
        <a-form-model-item label="姓名" prop="realName">
          <a-input v-model="queryRef.realName" placeholder="请输入" />
        </a-form-model-item>

        <a-form-model-item label="联系方式" prop="phone">
          <a-input v-model="queryRef.phone" placeholder="请输入" />
        </a-form-model-item>

        <a-form-model-item label="上级合伙人" prop="partnerName">
          <a-input v-model="queryRef.partnerName" placeholder="请输入" />
        </a-form-model-item>

        <a-form-model-item label="推客状态" prop="state">
          <a-select v-model="queryRef.state" placeholder='请选择' style="width: 120px">
            <a-select-option v-for='item in sharerState' :key='item.value' :value="item.value">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="推客等级" prop="level">
          <a-select v-model="queryRef.level" placeholder='请选择' style="width: 120px">
            <a-select-option v-for='item in levels' :key='item.level' :value="item.level">
              {{item.levelName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- </a-row> -->
        <!-- <a-row> -->
        <a-form-model-item label="添加时间" prop="dateRange">
          <a-range-picker v-model="dateRange" />
        </a-form-model-item>
        <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(0)">
          今
        </a-button>
        <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(1)">
          昨
        </a-button>
        <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(7)">
          近7天
        </a-button>
        <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(30)">
          近30天
        </a-button>
        <!-- </a-row> -->
        <a-form-model-item class="screen-btns">
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div style='margin-bottom:16px'>
      <!-- <a-button type='primary' style='margin-right:32px'>添加推客</a-button> -->
      <a-button type='primary' ghost :loading='exportLoading' @click='handleExport'>导出列表</a-button>
    </div>
    <base-table :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.userSharerId" :loading="loadingRef" @change="handleTableChange">
      <template #source="record">
        {{sharerSource.find(x=>x.value === record).name}}
      </template>
      <template #state="record">
        <span :style="{color: sharerState.find(x=>x.value === record).color}">{{sharerState.find(x=>x.value === record).name}}</span>
      </template>
      <template #operation="_,record">
        <div class='table-operations '>
          <a-button type='link' v-if="record.state === 1" @click='openRecordModal(record.userSharerId)'>收益记录</a-button>
          <a-button type='link' v-if="record.state === 1" @click='unbind(record.userSharerId)'>解绑</a-button>
          <a-button type='link' @click='handlePass(record.userSharerId)' v-if="record.state === 0">通过</a-button>
          <a-button type='link' @click='handleRefuse(record.userSharerId)' v-if="record.state === 0" style='color: #ff4d4f'>拒绝</a-button>
        </div>
      </template>
    </base-table>
    <a-modal v-model='recordModal' :width='600' title='收益记录' :footer="null">
      <a-spin :spinning='recordLoading'>
        <div class='record-list' v-if='recordList.length'>
          <div class='record-item' v-for='item in recordList' :key='item.userSharerAccountChangeBillId'>
            <span>{{item.createTime}}</span>
            <span style='margin: 0 16px 0 48px'>{{item.eventName}}</span>
            <span>{{item.amount}}元</span>
          </div>
        </div>
        <a-empty v-else></a-empty>
        <a-pagination style='text-align: right;' v-model='pagination.current' :pageSize="pagination.pageSize" size="small" :total="pagination.total" @change='changeRecordPage' :show-total="total => `共 ${pagination.total} 条`" />
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import moment from 'moment'
import { marketing } from '@/api'
import { sharerSource, sharerState } from '../../utils/constants'
import { useExport } from '@/utils/tools'
const columns = [
  {
    title: '姓名',
    width: 80,
    dataIndex: 'name',
  },
  {
    title: '联系方式',
    width: 140,
    dataIndex: 'mobile',
  },
  {
    title: '推客等级',
    width: 120,
    dataIndex: 'levelName',
  },
  {
    title: '所属上级',
    width: 120,
    dataIndex: 'partnerName',
  },
  {
    title: '累计收益',
    width: 80,
    dataIndex: 'totalAmount',
  },
  {
    title: '可提现收益',
    width: 80,
    dataIndex: 'amount',
  },
  {
    title: '状态',
    width: 80,
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: '添加方式',
    width: 120,
    dataIndex: 'source',
    scopedSlots: { customRender: 'source' },
  },
  {
    title: '添加时间',
    width: 160,
    dataIndex: 'applyTime',
  },
  {
    title: '操作',
    // width: 200,
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  setup (props, { root }) {
    const formRef = ref(null)
    const dateRange = ref([])
    const currentSharerId = ref('')
    const { exporting, exportFn } = useExport(() => marketing.exportSharerList(queryRef.value))
    watch(
      () => dateRange.value,
      (data) => {
        if (data.length) {
          queryRef.value.startTime = `${moment(dateRange.value[0]).format('YYYY-MM-DD')} 00:00:00`
          queryRef.value.endTime = `${moment(dateRange.value[1]).format('YYYY-MM-DD')} 23:59:59`
        } else {
          queryRef.value.startTime = ''
          queryRef.value.endTime = ''
        }
      }
    )

    const queryRef = ref({
      realName: '',
      phone: '',
      partnerName: '',
      orderState: '',
      startTime: '',
      endTime: '',
      state: undefined,
      level: undefined,
    })
    const recordModal = ref(false)
    const pagination = ref({
      current: 1,
      pageSize: 10,
      total: 0,
    })
    const recordLoading = ref(false)
    const dataRef = ref([])
    const pageRtv = ref({
      current: 1,
      size: 10,
      total: 0,
    })
    const recordList = ref([])
    const levels = ref([])
    const loadingRef = ref(false)
    onMounted(() => {
      Promise.all([getLevelData(), getTableData()])
    })
    async function getTableData () {
      loadingRef.value = true
      dataRef.value = []
      const { data, page } = await marketing.getSharerList({ ...queryRef.value, ...pageRtv.value })
      loadingRef.value = false
      data.forEach(x => {
        if (!x.partnerName) {
          x.partnerName = '无'
        }
      })
      dataRef.value = data || []
      pageRtv.value.total = page.total
    }

    function handleSearch () {
      pageRtv.value.current = 1
      getTableData()
    }

    async function handleReset () {
      formRef.value.resetFields()
      dateRange.value = []
      await root.$nextTick()
      handleSearch()
    }
    function unbind (id) {
      root.$confirm({
        title: '解绑操作提示',
        content: '确定要解绑此推客吗？',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          unbindSharer(id)
        },
      })
    }
    async function unbindSharer (id) {
      const { code, msg } = await marketing.unbindSharer({
        userSharerId: id
      })
      if (code === '00000') {
        root.$message.success('解绑成功')
      } else {
        root.$message.error(msg || '解绑失败')
      }
    }
    function handleTableChange ({ current }) {
      pageRtv.value.current = current
      getTableData()
    }

    function handleDateShortcut (day) {
      dateRange.value = [
        moment().subtract(day, 'd'),
        day === 1 ? moment().subtract(1, 'd') : moment(),
      ]
    }
    async function getLevelData () {
      const { data, msg, code } = await marketing.getLevelData()
      if (code === '00000') {
        levels.value = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function openRecordModal (id) {
      recordModal.value = true

      currentSharerId.value = id
      getRecordList()
    }
    async function getRecordList () {
      recordLoading.value = true
      const { data, msg, code, page } = await marketing.getRecordList({
        size: pagination.pageSize,
        current: pagination.current,
        userSharerId: currentSharerId.value
      })
      recordLoading.value = false
      if (code === '00000') {
        pagination.value.total = page.total
        recordList.value = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function changeRecordPage (current) {
      pagination.current = current
      getRecordList()
    }
    async function handlePass (userSharerId) {
      root.$confirm({
        title: '操作提示',
        content: '确认要通过此条申请？',
        onOk: async () => {
          const { code, msg } = await marketing.passSharer({
            userSharerId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    async function handleRefuse (userSharerId) {
      root.$confirm({
        title: '操作提示',
        content: '确认要拒绝此条申请？',
        onOk: async () => {
          const { code, msg } = await marketing.refuseSharer({
            userSharerId
          })
          if (code === '00000') {
            root.$message.success('操作成功')
            getTableData()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }

    return {
      queryRef,
      formRef,
      dateRange,
      handleDateShortcut,
      handleTableChange,
      handleReset,
      handleSearch,
      openRecordModal,
      handlePass,
      handleRefuse,
      columns,
      sharerSource,
      levels,
      sharerState,
      loadingRef,
      dataRef,
      pagination,
      currentSharerId,
      pageRtv,
      exportLoading: exporting,
      handleExport: exportFn,
      recordLoading,
      recordList,
      recordModal,
      unbind,
      changeRecordPage
    }
  },
}
</script>

<style lang="less" scoped>
@label-width: 84px;
.custom-screen {
  ::v-deep .solt-div {
    padding: 10px 0 0;
  }
  ::v-deep .ant-form-item-label {
    width: @label-width;
  }
}
.screen-btns {
  margin-left: @label-width;
}
.btn-date-shortcut {
  margin: 4px 12px 0 0;
  &:last-child {
    margin-right: 0;
  }
}
.record-list {
  margin-bottom: 32px;
  .record-item {
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
